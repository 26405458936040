















































import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { AuthStoreActions } from '@/store/auth.store';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const AuthStore = namespace('auth');

@Component({
  mixins: [validationMixin],
  validations: {
    emailCopy: { email }
  }
})
export default class PasswordRequestComponent extends mixins(ErrorMessageHandlerMixin, AxiosErrorHandlerMixin) {
  @Prop()
  public email?: string;

  @AuthStore.Action(AuthStoreActions.INITIATE_PASSWORD_RESET)
  private initiatePasswordResetAction!: (email: string) => Promise<any>;

  private emailCopy: string = '';

  private isValid: boolean = false;
  private isLoading: boolean = false;

  @Watch('email', { immediate: true })
  private onEmailChanged() {
    if (this.email) {
      this.emailCopy = this.email;
      this.isValid = !this.$v!.$invalid;
    }
  }
  
  private dismiss() {
    this.$v.$reset();
    this.$emit('closeDialog');
  }

  private async requestPassword() {
    this.isValid = !this.$v!.$invalid;

    if (this.isValid && !this.isLoading) {
      try {
        this.isLoading = true;
        await this.initiatePasswordResetAction(this.emailCopy);
        this.dismiss();
      } catch (e) {
        this.handleAxiosError(e, () => {
          switch (e.status) {
            case 403:
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PASSWORD_RESET_INVALID_ACCOUNT');
              break;
            default:
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_ERROR');
          }
        });
      } finally {
        this.isLoading = false;
      }
    }
  }

  private checkForm(type: string): boolean {
    this.isValid = !this.$v!.$invalid;
    return this.triggerValidation(type);
  }
}
